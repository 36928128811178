import React from 'react';
import NewsLetter from '../Content/NewsLetter';
import LocalizedLink from '../LocalizedLink';
import Translate from '../Translate/Translate';
import FooterBrands from './FooterBrands';
import { translations } from '../../stores';
import { Language } from '../../stores/Translations';
import { gql, useQuery } from '@apollo/client';

const GET_PAGES = gql`
  query Pages {
    pages {
      id
      slug
      title
    }
  }
`;
type PageItem = {
  id: string;
  title: string;
  slug: string;
};

export default function Footer() {
  const { data } = useQuery<{
    pages: PageItem[];
  }>(GET_PAGES);
  const pages = data?.pages || [];

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const currentLanguage = translations.language;

  return (
    <footer className="old-container">
      <div className="footer bg-black text-white rounded-xl bg-clip-border">
        <div className="top flex md:flex-row text-center flex-col">
          <div className="one-third flex-auto md:my-12 my-0 py-12  md:border-b-0 border-b-2 border-stone-800">
            <h2 className="text-3xl">
              <LocalizedLink onClick={scrollToTop} to={'/tule-toole'}>
                <Translate component="footer" keyword="jobs" />
              </LocalizedLink>
            </h2>
          </div>
          <div className="one-third side-borders flex-auto md:my-12 my-0 py-12 border-solid border-x-2 border-x-stone-800">
            <h2 className="text-3xl">
              <LocalizedLink onClick={scrollToTop} to={'/kauplused'}>
                <Translate component="footer" keyword="stores" />
              </LocalizedLink>
            </h2>
          </div>
          <div className="one-third flex-auto md:my-12 my-0 py-12 md:border-t-0 border-t-2 border-stone-800">
            <NewsLetter />
          </div>
        </div>
        <div className="middle border-y-2 border-stone-800 px-12">
          <FooterBrands />
        </div>
        <div className="bottom px-12 pt-24 flex text-base">
          <div className="left flex-1">
            <ul className="mb-4 text-sm">
              <li className="mb-4">
                Soovid teha koostööd? Võta ühendust!{' '}
                <p className="opacity-80">
                  <a className="underline" href="mailto:koostoo@rademar.ee">
                    koostoo@rademar.ee
                  </a>
                </p>
              </li>

              <li className="mb-4">
                Vajad infot toodete, tellitud kauba või muu e-poega seonduva
                kohta?{' '}
                <div className="opacity-80">
                  <a className="underline" href="mailto:epood@rademar.ee">
                    epood@rademar.ee
                  </a>
                  <p>E-R 09:00-16:30</p>
                  <p>Telefon: 615 4138</p>
                </div>
              </li>

              <li className="mb-4">
                Kas sul on meiega seoses mõni muu küsimus? Küsi julgelt!{' '}
                <div className="opacity-80">
                  <a className="underline" href="mailto:info@rademar.ee">
                    info@rademar.ee
                  </a>
                  <p>Telefon: 615 4130</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="right flex-1 hidden md:flex justify-end">
            <ul className="ml-10">
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tooted/naised'}>
                  <Translate component="footer" keyword="women" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tooted/mehed'}>
                  <Translate component="footer" keyword="men" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tooted/lapsed'}>
                  <Translate component="footer" keyword="kids" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tooted/rattad'}>
                  <Translate component="footer" keyword="bikes" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink
                  onClick={scrollToTop}
                  to={'/tooted/aksessuaarid'}
                >
                  <Translate component="footer" keyword="accessories" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/brandid'}>
                  <Translate component="footer" keyword="brands" />
                </LocalizedLink>
              </li>
            </ul>
            <ul className="ml-10">
              {pages.map((page) => (
                <li key={page.id} className="mb-2">
                  <LocalizedLink onClick={scrollToTop} to={`/${page.slug}`}>
                    {page.title}
                  </LocalizedLink>
                </li>
              ))}
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tagastamine'}>
                  <Translate component="footer" keyword="tagastamine" />
                </LocalizedLink>
              </li>
            </ul>
            <ul className="ml-10">
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/ettevottest'}>
                  <Translate component="footer" keyword="about-us" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/tule-toole'}>
                  <Translate component="footer" keyword="jobs" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/kauplused'}>
                  <Translate component="footer" keyword="stores" />
                </LocalizedLink>
              </li>
              <li className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={'/brandid'}>
                  <Translate component="footer" keyword="brands" />
                </LocalizedLink>
              </li>
              {currentLanguage === Language.RU ? null : (
                <li className="mb-2">
                  <LocalizedLink onClick={scrollToTop} to={'/blogi'}>
                    <Translate component="footer" keyword="news" />
                  </LocalizedLink>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="flex mt-12 md:hidden">
          <ul className="ml-[50px]">
            {pages.map((page) => (
              <li key={page.id} className="mb-2">
                <LocalizedLink onClick={scrollToTop} to={`/${page.slug}`}>
                  {page.title}
                </LocalizedLink>
              </li>
            ))}
            <li className="mb-2">
              <LocalizedLink onClick={scrollToTop} to={'/tagastamine'}>
                <Translate component="footer" keyword="tagastamine" />
              </LocalizedLink>
            </li>
          </ul>
        </div>
        <div className="social p-12 font-axi-bold">
          <a className="mr-7" href="https://www.facebook.com/rademar.ee/">
            Facebook
          </a>
          <a href="https://www.instagram.com/rademar.ee/?hl=en">Instagram</a>
        </div>
      </div>
    </footer>
  );
}
